



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ListsOfAssets } from '../types';
import { FETCH_LISTS_OF_ASSETS } from '../store';
import { ApiResponse } from '@/components/types';

const ListOfAssets = namespace('listOfAssets');

@Component({
  inheritAttrs: false
})
export default class ListOfAssetsSelect extends Vue {
  @Prop({ type: [Array, String] }) value?: string | string[];
  @Prop({ type: Boolean, default: true }) required!: boolean;
  @Prop({ type: Boolean, default: false }) multiple!: boolean;

  @ListOfAssets.State('listsOfAssets') list!: ListsOfAssets;
  @ListOfAssets.Action(FETCH_LISTS_OF_ASSETS) fetch!: () => Promise<ApiResponse>;

  get items() {
    return this.list.items
      .filter((listOfAssets) => listOfAssets.closed == null)
      .map(({ conservatee, ...listOfAssets }) => ({
        id: listOfAssets.id,
        name: `${conservatee.surname}, ${conservatee.forename}`
      }))
      .sort((first, second) => first.name.localeCompare(second.name));
  }

  created() {
    if (this.list.items.length !== 0) return;

    return this.fetch();
  }
}
