







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BANK_ACCOUNT_IMPORT } from '@/modules/listOfAssets/store/financeAccount';
import DatePicker from '@/components/form/DatePicker.vue';
import CardRow from '@/components/CardRow.vue';
import ConservatorshipSelect from '../../conservatorship/components/ConservatorshipSelect.vue';
import { ImportSEPAAccount, ImportSEPAAccountParams } from '../types';
import { createSEPAAccountImport } from '@/modules/listOfAssets/model';
import { ApiResponse } from '@/components/types';
import ListOfAssetsSelect from '@/modules/listOfAssets/components/ListOfAssetsSelect.vue';
import BankAccountTypeSelect from '@/modules/listOfAssets/components/BankAccountTypeSelect.vue';

const Import = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    CardRow,
    DatePicker,
    ListOfAssetsSelect,
    BankAccountTypeSelect,
    ConservatorshipSelect,
  }
})
export default class NewSepaAccountCard extends Vue {
  @Prop({ type: Object, required: true }) account!: ImportSEPAAccount;
  @Prop({ type: String, required: true }) username!: string;
  @Prop({ type: String, required: true }) pin!: string;

  @Import.Action(BANK_ACCOUNT_IMPORT) addBankAccount!: (params: ImportSEPAAccountParams) => Promise<ApiResponse>;

  accountImport: ImportSEPAAccountParams = createSEPAAccountImport(
    this.$auth.user().id,
    this.account,
    undefined,
    this.username,
    this.pin
  );

  error: any = null;
  loading: boolean = false;

  importAccount() {
    this.error = null;

    return this.addBankAccount(this.accountImport);
  }

  success() {
    this.$emit('saved', this.account.iban);
  }
}
